define("ember-light-table/components/lt-spanned-row", ["exports", "@ember/component", "ember-light-table/templates/components/lt-spanned-row"], function (_exports, _component, _ltSpannedRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _ltSpannedRow.default,
    colspan: 1,
    tagName: '',
    visible: true
  });
});